import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1066.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
<path d="M0 5400 l0 -5400 5330 0 5330 0 0 5400 0 5400 -5330 0 -5330 0 0
-5400z m3962 3073 c51 -40 131 -102 178 -138 184 -142 313 -241 334 -255 11
-8 41 -31 66 -51 38 -31 338 -262 440 -339 19 -14 68 -52 110 -85 42 -33 105
-82 140 -109 36 -27 71 -56 78 -65 8 -11 12 -66 12 -197 0 -180 -3 -214 -21
-214 -5 0 -59 39 -120 88 -62 48 -127 98 -145 112 -54 41 -473 367 -591 460
-137 107 -272 210 -277 210 -3 0 -7 -654 -8 -1452 l-3 -1453 -159 -3 c-88 -1
-163 1 -168 6 -9 9 -12 3558 -2 3573 7 11 4 13 136 -88z m-560 -186 c20 -17
84 -69 142 -115 l106 -84 0 -1549 0 -1550 -152 3 -153 3 -3 1663 c-2 1326 0
1662 10 1662 7 0 30 -15 50 -33z m3866 -1664 l-3 -1638 -149 -3 c-101 -2 -153
1 -162 9 -12 10 -14 209 -14 1302 0 709 -2 1292 -4 1294 -2 3 -27 -12 -54 -34
-28 -21 -92 -70 -144 -109 -84 -65 -282 -218 -398 -309 -86 -68 -225 -176
-245 -191 -11 -8 -92 -72 -181 -142 -89 -70 -203 -158 -254 -197 -51 -38 -134
-103 -184 -144 -130 -106 -120 -104 -209 -35 -42 33 -108 84 -148 114 -39 30
-90 69 -113 87 -22 18 -60 47 -84 65 -40 31 -104 83 -163 131 -15 12 -50 40
-79 62 -29 22 -72 56 -96 75 -23 19 -64 51 -90 70 -25 19 -63 50 -83 67 -20
18 -39 33 -43 33 -4 0 -19 11 -33 25 l-25 26 0 204 c0 115 4 205 9 205 10 0
92 -60 150 -110 20 -16 74 -59 121 -95 126 -96 174 -134 250 -197 38 -32 71
-58 75 -58 3 0 38 -26 78 -58 83 -66 112 -89 222 -174 44 -34 89 -70 99 -80
29 -26 41 -23 104 26 31 25 118 92 192 150 74 59 155 122 180 141 25 18 148
114 274 212 125 98 235 183 244 188 9 6 42 30 73 55 32 25 97 75 146 113 48
37 124 95 169 130 44 34 116 89 159 122 43 33 146 115 229 182 83 67 159 122
169 122 16 1 17 -81 15 -1636z m-478 -508 l0 -1126 -157 3 -158 3 -2 779 c-1
428 -4 785 -7 793 -5 11 -10 11 -26 3 -19 -10 -158 -116 -202 -154 -9 -8 -29
-23 -45 -33 -15 -9 -30 -20 -33 -23 -17 -19 -141 -110 -150 -110 -5 0 -10 -4
-10 -8 0 -5 -33 -33 -72 -63 -120 -90 -562 -432 -576 -446 -7 -7 -18 -13 -23
-13 -5 0 -9 81 -9 194 l0 194 78 60 c42 34 94 74 114 89 20 15 71 55 114 88
42 33 92 71 110 85 71 52 259 198 342 266 48 39 128 102 178 140 145 110 332
257 424 333 47 38 91 70 98 70 9 1 12 -230 12 -1124z m-4019 -1864 c21 -1 34
-14 65 -63 21 -35 45 -74 52 -88 7 -14 19 -32 26 -40 7 -8 25 -36 40 -62 29
-51 40 -58 49 -30 4 9 44 76 89 147 83 130 83 130 127 136 24 3 66 4 93 1 l48
-5 -2 -446 -3 -446 -85 0 -85 0 -3 273 c-2 208 -5 272 -15 272 -6 0 -36 -41
-66 -91 -29 -49 -62 -94 -72 -99 -21 -12 -69 -13 -86 -2 -15 9 -49 59 -92 135
-18 31 -37 57 -42 57 -5 0 -9 -111 -9 -269 0 -306 8 -281 -95 -281 -105 0 -95
-47 -95 451 l0 440 23 9 c12 5 42 7 67 5 25 -2 57 -4 71 -4z m2141 -252 c50
-13 118 -66 145 -114 22 -39 23 -47 20 -285 l-2 -245 -80 -3 c-71 -3 -81 -1
-95 18 l-16 22 -40 -24 c-33 -19 -53 -23 -124 -23 -75 0 -91 3 -132 28 -27 15
-54 40 -62 55 -37 72 -26 194 22 245 52 55 97 70 222 76 l115 6 0 35 c0 30 -4
36 -37 48 -48 19 -101 9 -132 -25 -24 -26 -26 -26 -108 -17 -89 10 -97 16 -84
69 28 110 219 176 388 134z m-1062 -210 c0 -180 2 -215 17 -240 33 -55 96 -62
150 -15 l28 24 3 221 4 221 89 0 89 0 0 -32 c2 -304 -1 -601 -5 -609 -4 -5
-42 -9 -87 -9 -71 0 -80 2 -85 20 -7 26 -18 25 -56 -2 -27 -19 -45 -23 -107
-23 -96 1 -148 26 -191 92 l-29 47 0 258 0 258 90 0 90 0 0 -211z m1014 -653
c78 -27 146 -107 146 -171 0 -23 -4 -25 -40 -25 -37 0 -39 2 -46 35 -7 38 -50
86 -86 97 -79 22 -159 -4 -190 -62 -15 -30 -18 -59 -18 -198 0 -138 3 -167 17
-190 27 -41 54 -60 96 -67 87 -15 157 22 181 96 9 28 16 35 44 37 42 4 50 -15
28 -73 -52 -135 -237 -179 -369 -88 -68 47 -77 80 -77 286 0 194 7 223 65 277
58 55 167 75 249 46z m3013 -54 c6 -4 13 -30 15 -57 l3 -50 58 -3 c56 -3 57
-4 57 -32 0 -26 -4 -30 -27 -31 -16 -1 -34 -1 -40 0 -7 1 -22 -3 -33 -9 -19
-10 -20 -21 -20 -150 0 -179 5 -190 85 -202 15 -2 21 -12 23 -37 l3 -33 -65 4
c-112 7 -125 36 -126 269 0 156 -1 159 -51 159 -14 0 -19 7 -19 25 0 19 7 26
33 33 29 7 32 12 35 52 5 63 31 86 69 62z m-2461 -125 c91 -39 125 -93 132
-209 5 -87 -15 -166 -51 -207 -72 -79 -231 -79 -304 0 -58 64 -76 220 -36 310
42 95 168 146 259 106z m351 -7 c6 -24 20 -25 44 -4 9 8 42 18 72 21 44 5 63
1 100 -17 74 -37 81 -61 85 -282 l3 -188 -40 0 -39 0 -4 181 -3 181 -33 29
c-23 21 -43 29 -69 29 -42 0 -99 -34 -107 -65 -3 -11 -6 -95 -6 -187 l0 -168
-39 0 c-22 0 -41 5 -44 13 -3 9 -2 232 3 460 0 26 70 24 77 -3z m523 6 c0 -26
18 -27 60 -5 33 17 52 21 95 16 65 -7 100 -28 126 -78 22 -44 24 -71 21 -267
l-2 -143 -37 3 -38 3 -5 182 c-5 177 -6 182 -29 202 -50 44 -128 38 -165 -12
-21 -29 -23 -52 -26 -360 0 -15 -7 -18 -37 -15 l-38 3 -3 230 c-1 126 0 235 2
242 7 19 76 17 76 -1z m713 3 c40 -11 80 -47 102 -94 17 -34 35 -150 26 -163
-3 -5 -73 -10 -156 -11 -82 -1 -154 -6 -159 -11 -15 -15 14 -86 45 -109 48
-35 150 -25 188 18 12 15 21 17 39 11 30 -12 28 -36 -5 -66 -75 -69 -213 -72
-286 -6 -55 49 -84 203 -58 300 30 109 143 165 264 131z m557 -14 c63 -33 111
-115 79 -136 -31 -19 -58 -10 -73 25 -25 60 -111 85 -175 51 -81 -41 -81 -279
0 -320 61 -32 136 -14 170 42 18 29 26 34 53 31 40 -4 44 -26 12 -76 -34 -55
-109 -86 -188 -79 -74 7 -115 31 -152 90 -54 87 -46 254 15 331 18 22 47 40
99 60 27 10 126 -1 160 -19z"/>
<path d="M4756 3630 c-43 -13 -66 -39 -66 -75 0 -59 66 -89 132 -62 53 22 75
66 69 135 -1 14 -89 15 -135 2z"/>
<path d="M5276 2879 c-39 -31 -56 -77 -56 -154 0 -120 47 -181 133 -172 82 7
117 63 117 184 0 100 -39 153 -117 160 -39 4 -53 1 -77 -18z"/>
<path d="M6849 2868 c-25 -28 -49 -92 -38 -103 7 -6 50 -8 147 -7 92 2 92 2
92 27 0 66 -55 115 -130 115 -34 0 -47 -6 -71 -32z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
